/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Fullmap, FullmapWrap, FullmapCover, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Locatie"}>
        <SiteHeader />

        <Column className="--center pb--80 pt--80" name={"1vxxjbff4ua"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" anim={"2"} animS={"5"} style={{"maxWidth":900}} columns={"1"}>
            
            <ColumnWrapper className="pt--40">
              
              <Title className="title-box title-box--left fs--102 mt--10" content={"Locatie<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"34a2hs3eayb"}>
          
          <ColumnWrap className="column__flex --center el--1 mt--30 flex--top" style={{"maxWidth":900}} columns={"1"}>
            
            <ColumnWrapper className="--left mt--06 pt--0">
              
              <Subtitle className="subtitle-box" content={"t' Fornuis aan huis te Geraardsbergen<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Zoals thuiskomen&nbsp;♡"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"gy5v1o62n8"}>
          
          <FullmapWrap className="pb--20 pt--20">
            
            <Subtitle className="subtitle-box subtitle-box--center" content={"<span style=\"color: var(--color-custom-1--95);\">Schillebeekstraat 89, 9500 Geraardsbergen</span>"}>
            </Subtitle>

          </FullmapWrap>

          <FullmapWrap className="--full">
            
            <FullmapCover zoom={"13"} place={"Schillebeekstraat 89, 9500 Geraardsbergen"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="--center pb--60 pt--80" name={"1vxxjbff4ua"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" anim={"2"} animS={"5"} style={{"maxWidth":1150}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/30673/81e9ee03758743fd987e714cb58ed4cf_s=660x_.jpg"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/30673/81e9ee03758743fd987e714cb58ed4cf_s=350x_.jpg 350w, https://cdn.swbpg.com/t/30673/81e9ee03758743fd987e714cb58ed4cf_s=660x_.jpg 660w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--40" name={"footer-2"}>
          
          <ColumnWrap className="column__flex --center el--1" anim={null} animS={null} style={{"maxWidth":1090}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--102 mt--10" content={"We kijken ernaar&nbsp;uit<br>om u te zien!"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}